/* Basic, generic flexbox styles */
.flex {
  display: flex;
}

.flex--horizontal-end {
  justify-content: flex-end;
}

.flex--column {
  flex-direction: column;
}

.flex--full-height {
  height: 100%;
}

.flex--vertical-center {
  align-items: center;
}

.flex--horizontal-center {
  justify-content: center;
}

/* Convenient shorthand for centering */
.flex--full-center {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.flex__child {
  flex: 0 1 auto;
}

.flex__child--grow {
  flex: 1;
}
